<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
import VuePhoneNumberInput from 'vue-phone-number-input';
import moment from "moment";
//import { format } from "date-fns";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Ajouter Utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, VuePhoneNumberInput },
  data() {
    return {
      title: "Ajouter utilisateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      genres: [
        {
          name: "Masculin",
          content: "M",
        },
        {
          name: "Féminin",
          content: "F",
        },
        {
          name: "Autres",
          content: "O",
        },
      ],
      preferencys: [
        {
          name: "CONDUCTEUR",
        },
        {
          name: "PARTICULIER",
        },
        {
          name: "TAXIMAN",
        },
        {
          name: "SOCIETE",
        },
      ],
      valuePrefency:null,
      musiquePrefs: [
        {
          name: "ALWAYS",
        },
        {
          name: "NEVER",
        },
        {
          name: "DEPEND",
        },
      ],
      musiquePrefsVal:null,
      discutePrefs: [
        {
          name: "ALWAYS",
        },
        {
          name: "NEVER",
        },
        {
          name: "DEPEND",
        },
      ],
      discutePrefsVal:null,
      fumerPrefs: [
        {
          name: "ALWAYS",
        },
        {
          name: "NEVER",
        },
        {
          name: "DEPEND",
        },
      ],
      fumerPrefsVal:null,
      mangerPrefs: [
        {
          name: "ALWAYS",
        },
        {
          name: "NEVER",
        },
        {
          name: "DEPEND",
        },
      ],
      mangerPrefsVal:null,
      mobilPrefs: [
        {
          name: "ALWAYS",
        },
        {
          name: "NEVER",
        },
        {
          name: "DEPEND",
        },
      ],
      mobilPrefsVal:null,
      arretsPrefs: [
        {
          name: "ALWAYS",
        },
        {
          name: "NEVER",
        },
        {
          name: "DEPEND",
        },
      ],
      climOption:[
        {
          name: "OUI",
        },
        {
          name: "NON",
        },
      ],
      clim:null,
      arretsPrefsVal:null,
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      form: {
        lastname: "",
        firstname: "",
        gender: "",
        state: "",
        zipcode: "",
        phoneNumber: "",
      },
      fileProfil:null,
      filePermis:null,
      fileMoto:null,
      fileCni:null,
      user: {
        firstname: "",
        lastname: "",
        gender: "",
        adress: "",
        profession: "",
        birthday: "",
        phoneNumber: "",
        email: "",
        password: "",
        about: "",
        permisPlace:"",
        permisDate:"",
        numberPermis:"",
        marque:"null",
        model:"",
        motorisation:"",
        couleur:"",
        carteGrise:"",
        siege:"",
        immatriculation:"",
        circulation:"",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      lastname: { required },
      firstname: { required },
      gender: { required },
      birthday: { required },
      profession: { required },
    },
    user: {
      firstname: { required },
      lastname: { required },
      gender: { required },
      phoneNumber: { required },
      email: { required, email },
      password: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    this.thenDay = moment().subtract(18, "years").format(" YYYY-MM-DD");

  },
  methods: {
    async addUser() {
      const addUser = await apiRequest(
        "POST",
        "admin/user",
        {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          gender: this.user.gender.content,
          birthday: this.user.birthday,
          profession: this.user.profession,
          adresse: this.user.adress,
          tel: this.user.phoneNumber,
          email: this.user.email,
          password: this.user.password,
          about: this.user.about,
        },
        false
      );
      if(addUser && addUser.data){
        
          this.addPreferences(addUser.data.id,this.user.gender.content);
          this.addPermis(addUser.data.id);
          this. addAutoMoto(addUser.data.id);
          //ENVOIE DES IMAGES
          this.addPermi(addUser.data.id);
          this.addCarteni(addUser.data.id);
          this.addauto(addUser.data.id);
          this.addprof(addUser.data.id);
    


      }
    },
    handleFileProfil() {
      this.fileProfil = this.$refs.file.files[0];
    },
    handleFilePermis(){
     this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto(){
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni(){
    this.fileCni = this.$refs.fileCni.files[0];
    },

    async addPermi(id){
   if(this.filePermis ){

          let formData = new FormData();
          formData.append("idUser", id);
          formData.append("file", this.filePermis);

      const returnFilePermis= await apiRequest("POST", "admin/user-permis-pic", formData, true);

      if (returnFilePermis && returnFilePermis.data) {
        console.log("PermisImage:", returnFilePermis);
        
      }
        }
    },
    async addCarteni(id){
    if(this.fileCni){

          let formData = new FormData();
          formData.append("idUser", id);
          formData.append("cni", this.fileCni);

      const returnFileProfil= await apiRequest("POST", "admin/user-cni-pic", formData, true);

      if (returnFileProfil && returnFileProfil.data) {
        console.log("CniImage:", returnFileProfil);
        
      }
        }
    },
    async addauto(id){
  if(this.fileMoto ){

          let formData = new FormData();
          formData.append("idUser", id);
          formData.append("file", this.fileMoto);

      const returnFileMoto= await apiRequest("POST", "admin/user-automoto-pic", formData, true);

      if (returnFileMoto && returnFileMoto.data) {
        console.log("AutoImage:", returnFileMoto);
        
      }
        }
    },
    async addprof(id){
   if(this.fileProfil){

          let formData = new FormData();
          formData.append("id", id);
          formData.append("avatar", this.fileProfil);

      const returnFileProfil= await apiRequest("POST", "admin/user-pic", formData, true);

      if (returnFileProfil && returnFileProfil.data) {
        console.log("ProfilImage:", returnFileProfil);
        
      }
        }
    },
    async addPreferences(id,genres){
  const addPref = await apiRequest(
        "POST",
        "admin/user-preferences",
        {
          idUser:id,
          userType:this.valuePrefency.name?this.valuePrefency.name:"CONDUCTEUR",
          genre:genres,
          enjoyMusicInCar:this.musiquePrefsVal.name?this.musiquePrefsVal.name:"NEVER",
          discussInCar:this.discutePrefsVal.name?this.discutePrefsVal:"NEVER",
          smokeInCar:this.fumerPrefsVal.name?this.fumerPrefsVal.name:"NEVER",
          eatInCar:this.mangerPrefsVal.name?this.mangerPrefsVal.name:"NEVER",
          acceptPeopleDisabilities:this.mobilPrefsVal.name?this.mobilPrefsVal.name:"NEVER",
          tolerateStops:this.arretsPrefsVal.name?this.arretsPrefsVal.name:"NEVER"
        },
        false
      );
      if(addPref && addPref.data){
       console.log("Preférence",addPref)
      
      }
    },

    async addPermis(id){
    const addPerm = await apiRequest(
        "POST",
        "admin/user-permis-data",
        {
          idUser:id,
          permisCategorie:"",
          issueDate:"",
          startDate:this.user.permisDate,
          permisNumber:this.user.permisNumber,
          deliveredPlace:this.user.permisPlace,
        },
        false
      );
      if(addPerm && addPerm.data){

         console.log("",addPerm)
      }
    },

    async addAutoMoto(id){
    const addAuto= await apiRequest(
        "POST",
        "admin/user-automoto-data",
        {
          idUser:id,
         autoMotoName:this.user.marque,
         autoMotoImmatriculation:this.user.circulation,
         autoMotoModel:this.user.model,
         autoMotoCategorie:"",
         autoMotoCouleur:this.user.couleur,
         autoMotoNumeroCarteGrise:this.user.carteGrise,
         autoMotoMotorisation:this.user.motorisation,
         autoMotoAnneeMiseEnCirculation:this.user.circulation,
        },
        false
      );
      if(addAuto && addAuto.data){
      console.log("",addAuto)
      }
    },
    
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {

      this.submitted = true;
      this.$v.$touch();

    },

    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;

      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;

      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="">
                <div class="">
                  <div class="form-group">
                    <label for="firstname">Nom</label>
                    <input
                      id="firstname"
                      v-model="user.lastname"
                      type="text"
                      class="form-control"
                      placeholder="Nom"
                      :class="{
                        'is-invalid': submitted && $v.user.lastname.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.lastname.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.lastname.required"
                        >Le nom est requis.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="validationCustom02">Prénom</label>
                    <input
                      id="validationCustom02"
                      v-model="user.firstname"
                      type="text"
                      class="form-control"
                      placeholder="Prénom"
                      value="Otto"
                      :class="{
                        'is-invalid': submitted && $v.user.firstname.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.firstname.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.firstname.required"
                        >Le prénom est requis.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="">
                  <div class="form-group">
                    <label for="validationCustom03">Genre</label>
                    <multiselect
                      v-model="user.gender"
                      :options="genres"
                      label="name"
                      track-by="content"
                      :class="{
                        'is-invalid': submitted && $v.user.gender.$error,
                      }"
                    ></multiselect>
                  
                    <div
                      v-if="submitted && $v.user.gender.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.gender.required"
                        >Le genre est requis.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="validationCustom04">Date de naissance</label>
                    <input
                      id="validationCustom04"
                      v-model="user.birthday"
                      type="date"
                      class="form-control"
                      placeholder=""
                      :max="`${this.thenDay}`"
                    />
                    <!--  <div v-if="submitted && $v.form.state.$error" class="invalid-feedback">
                      <span v-if="!$v.form.state.required">This value is required.</span>
                    </div> -->
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="validationCustom05">Profession</label>
                    <input
                      id="validationCustom05"
                      v-model="user.profession"
                      type="text"
                      class="form-control"
                      placeholder="Profession"
                    />
                    <!-- <div v-if="submitted && $v.form.zipcode.$error" class="invalid-feedback">
                      <span v-if="!$v.form.zipcode.required">This value is required.</span>
                    </div> -->
                  </div>
                </div>
                <div class="">
                  <div class="form-group position-relative">
                    <label for="validationTooltip01">Adresse</label>
                    <input
                      id="validationTooltip01"
                      v-model="user.adress"
                      type="text"
                      class="form-control"
                      placeholder="Adresse"
                      value="Mark"
                    />
                    <!-- <div v-if="submitform && $v.tooltipform.fname.$error" class="invalid-feedback">
                      <span v-if="!$v.tooltipform.fname.required">Please provide valid First name.</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="tooltipForm">
              <div class="">
                <div class="">
                  <div class="form-group position-relative">
                    <label for="validationTooltip02">Téléphone</label>
                   <!--  <MazPhoneNumberInput
                      :class="{
                        'is-invalid': submitform && $v.user.phoneNumber.$error,
                      }"
                      v-model="user.phoneNumber"
                      default-country-code="BJ"
                      :translations="{
                        countrySelectorLabel: 'Code pays',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de téléphone',
                        example: 'Exemple :',
                      }"
                    /> -->

                    <VuePhoneNumberInput
                    :class="{
                        'is-invalid': submitform && $v.user.phoneNumber.$error,
                      }"
                    v-model="user.phoneNumber"  default-country-code="BJ" 
                    :translations="{
                        countrySelectorLabel: 'Code pays',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de téléphone',
                        example: 'Exemple :',
                        fetchCountry:true
                      }"
                    
                    />
                    {{user.phoneNumber}}
                    <div
                      v-if="submitform && $v.user.phoneNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.phoneNumber.required"
                        >Le numéro de téléphone est requis.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="form-group position-relative">
                    <label for="validationTooltipUsername">Email</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername"
                        v-model="user.email"
                        type="email"
                        class="form-control"
                        placeholder="email"
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.user.email.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.required"
                          >L'e-mail est requis.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="">
                  <div class="form-group position-relative">
                    <label for="validationTooltip03">Mot de passe</label>
                    <input
                      id="password"
                      v-model="user.password"
                      type="password"
                      class="form-control"
                      placeholder="Mot de passe"
                      :class="{
                        'is-invalid': submitform && $v.user.password.$error,
                      }"
                    />
                    <input
                      type="checkbox"
                      name=""
                      class="form-check-input ml-1"
                      id="showPassword"
                      @click="toogleShowPassword"
                      v-model="showPassword"
                    />

                    <div>
                      <label
                        class="form-check-label ml-5 mt-3"
                        for="showPassword"
                        >Voir mot de passe</label
                      >
                    </div>
                    <div
                      v-if="submitform && $v.user.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password.required"
                        >Le mot de passe est requis.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <label for="about">A propos</label>
                    

                    <textarea
                      v-model="user.about"
                      id="about"
                      class="form-control"
                      name="textarea"
                      placeholder="A propos de vous"
                      rows="5"
                    ></textarea>

                    <!--   <textarea
                      class="form-control"
                      id="about"
                      v-model="user.about"
                      cols="150"
                      rows="5"
                      placeholder="A propos de vous"
                    ></textarea> -->
                    <!-- <div v-if="submitform && $v.tooltipform.state.$error" class="invalid-feedback">
                      <span v-if="!$v.tooltipform.state.required">Please provide a valid state.</span>
                    </div> -->
                  </div>
                </div>
              </div>
               <div>
                 <label for="about">Photo de profil</label>
                    <input
                      type="file"
                      id="file"
                      ref="file"
                       v-on:change="handleFileProfil"
                      class="form-control"
                      placeholder="Photo de permis"
                    />
                  </div>
                  <div>
                 <label for="about">Photo carte d'identité</label>
                    <input
                      type="file"
                      id="fileCni"
                      ref="fileCni"
                       v-on:change="handleFileCni"
                      class="form-control"
                      placeholder="Photo de permis"
                    />
                  </div>
            </form>
          </div>
        </div>
    
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="tooltipForm">
              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label">Type d'utilisateur :</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="valuePrefency"
                        :options="preferencys"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label"
                        >Écoutez-vous de la musique en voiture ?
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="musiquePrefsVal"
                        :options="musiquePrefs"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label"
                        >Vous aimez discuter en voiture ?</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="discutePrefsVal"
                        :options="discutePrefs"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label"
                        >Peut-on fumer en votre présence ?</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="fumerPrefsVal"
                        :options="fumerPrefs"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label"
                        >Mangez vous en voiture ?</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="mangerPrefsVal"
                        :options="mangerPrefs"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label"
                        >Acceptez-vous les personnes à moibilité réduite?</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="mobilPrefsVal"
                        :options="mobilPrefs"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <label class="control-label"
                        >Tolérez-vous des arrêtes durant le trajet ?</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div>
                      <multiselect
                        v-model="arretsPrefsVal"
                        :options="arretsPrefs"
                        label="name"
                        track-by="name"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" @submit.prevent="tooltipForm">
            <label for="validationCustom05">Permis de conduire</label>

            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                     <label for="about">Lieu d'obtention du permis</label>
                    <input
                      id="validationCustom06"
                      v-model="user.permisPlace"
                      type="text"
                      class="form-control"
                      placeholder="Lieu d'obtention du permis"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <label for="about">Date d'obtention du permis</label>
                    <input
                      id="validationCustom07"
                      v-model="user.permisDate"
                      type="date"
                      class="form-control"
                      placeholder=""
                      :max="`${this.thenDay}`"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <label for="about">Numéro de permis</label>
                    <input
                      id="validationCustom08"
                      v-model="user.numberPermis"
                      type="text"
                      class="form-control"
                      placeholder="Numéro de permis"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <label for="about">Photo du permis</label>
                    <input
                      type="file"
                      id="filePermis"
                      ref="filePermis"
                      class="form-control"
                       v-on:change="handleFilePermis"
                      placeholder="Photo de permis"
                    />
                  </div>
                </div>
              </div>
            </div>

            <label for="validationCustom09">Auto/Moto</label>

            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom10"
                     v-model="user.marque"
                      type="text"
                      class="form-control"
                      placeholder="Marque"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom11"
                      v-model="user.model"
                      type="text"
                      class="form-control"
                      placeholder="Modèle"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom12"
                     v-model="user.motorisation"
                      type="text"
                      class="form-control"
                      placeholder="Motorisation"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom13"
                      v-model="user.couleur"
                      type="text"
                      class="form-control"
                      placeholder="Couleur"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom14"
                      v-model="user.carteGrise"
                      type="text"
                      class="form-control"
                      placeholder="Numéro de carte grise"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom15"
                      v-model="user.siege"
                      type="text"
                      class="form-control"
                      placeholder="Nombre de sièges"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <multiselect
                      v-model="clim"
                      :options="climOption"
                      label="name"
                      track-by="name"
                      placeholder="Climatisation"
                    ></multiselect>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom16"
                      v-model="user.immatriculation"
                      type="text"
                      class="form-control"
                      placeholder="Plaque d'immatriculation"
                    />
                  </div>
                </div>
              </div>
            </div>

<div class="form-group">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      id="validationCustom017"
                      v-model="user.circulation"
                      type="text"
                      class="form-control"
                      placeholder="Année de mise en circulation"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div>
                    <input
                      type="file"
                      id="fileMoto"
                      ref="fileMoto"
                       v-on:change="handleFileMoto"
                      class="form-control"
                      placeholder="Photo de permis"
                    />
                  </div>
                </div>
              </div>
            </div>
<button
                class="btn btn-primary float-right"
                type="submit"
                v-on:click="addUser()"
              >
                Ajouter
              </button>


          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
 background-color: #d5d5d5; 

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}
.checker {
  margin-top: 0.444rem;
  display: inline-flex;
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.656rem;
  margin-bottom: 0;

}

input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";

  font-size: 20px;
  color: white;
  display: none;
}
input[type="checkbox"]:hover {
  background-color: #a5a5a5;
}

input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}
input[type="checkbox"]:checked:after {
  display: block;
}

</style>
